@import url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css);
body {
  background-color: #ffffff;
}

body,
button,
html,
input,
select,
textarea,
div,
p,
span,
label {
  font-family: "Lato", "Open Sans", "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif" !important;
}

html,
body {
  width: 100%;
  height: 100%;
}

input:focus {
  border: solid 1px #0099ff;
}

.b-1-ccc {
  border: 1px solid #ccc !important;
}

.br-1-blue {
  border: 1.5px solid #4c84ff;
}

.br-1-grey {
  border: 1px solid #b3b3b3;
}
.br-1-lightGrey {
  border: 1px solid #7f7f7f;
}
.br-1-4b2 {
  border: 1px solid #3564b2;
}
.br-1-cff {
  border: 1px solid #ccdcff;
}
.br-1-b4d {
  border: 1px solid #172b4d;
}
.br-1-4ff {
  border: 1.8px solid #4c84ff;
}
.br-136-4ff {
  border: 1.36346px solid #4c84ff;
}

#root {
  min-height: 100%;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.display-inline {
  display: inline;
}

.display-grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: 1fr 1fr;
}
.grid-cols-3 {
  grid-template-columns: 1fr 1fr 1fr;
}
.grid-cols-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

/*Cursor-CSS*/

.trucate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/*Borders*/

.br-none {
  border: none !important;
}
.br-blue {
  border-color: #4c84ff;
}

.b-1-ccc {
  border: 1px solid #ccc;
}

.b-vertical-line {
  border: 0.3px solid #e0e0e2;
}

.b-1-input {
  border: 1px solid #d9d9d9;
}

.b-1-white {
  border: 1px solid #e6e6e6;
}

.b-1-grey {
  border: 1px solid #e0e0e2;
}

.b-1-blue {
  border: 1px solid #0052cc;
}

.b-1-bluish {
  border: 1px solid #1b5ea9;
}

.b-1-lightBlue-100 {
  border: 1px solid #99b9ff;
}

.font-color-lightgrey {
  color: #fafafa;
}

.font-color-green {
  color: #66dc8e;
}
.font-color-264780 {
  color: #264780;
}

.font-color-light-grey-black {
  color: #808080;
}
.font-color-red {
  color: red;
}

/*Border-radius*/

.br-3 {
  border-radius: 3px;
}

.br-4 {
  border-radius: 4px;
}
.br-8 {
  border-radius: 8px;
}
.br-16 {
  border-radius: 16px !important;
}

.br-18 {
  border-radius: 18px;
}
.br-32 {
  border-radius: 32px;
}

.br-35 {
  border-radius: 35px;
}
.br-20 {
  border-radius: 20px;
}

.br-24 {
  border-radius: 24px;
}

.br-25 {
  border-radius: 25px;
}

.br-30 {
  border-radius: 30px !important;
}
.br-90 {
  border-radius: 90px !important;
}
.br-50-per {
  border-radius: 50%;
}
/*line-height*/

.l-h-1 {
  line-height: 1.2 !important;
}

.l-h-1-4 {
  line-height: 1.4 !important;
}

.l-h-52 {
  line-height: 52px !important;
}

.l-h-120 {
  line-height: 120% !important;
}
.l-h-150 {
  line-height: 150% !important;
}
.height-auto {
  height: auto !important;
}

/*Font-sizes*/
.fs-9 {
  font-size: 9px;
}
.fs-sm {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}
.fs-25 {
  font-size: 25px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-40 {
  font-size: 40px;
}

.fs-48 {
  font-size: 48px !important;
}
.fs-200 {
  font-size: 50px;
}
.fs-8-10-400 {
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
}

.fs-8-10-600 {
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
}
.fs-12-10-600 {
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
}
.fs-12-18-500 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.fs-12-14-500 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.fs-12-14-600 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}
.fs-18-22-500 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.fs-18-150-400 {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
}
.fs-40-32-500 {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 32px;
}
.fs-21-140-400 {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 140%;
}
.fs-21-140-700 {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 140%;
}

.fs-24-28-600 {
  font-weight: 600;
  font-size: 23.427px;
  line-height: 28px;
}
.fs-28-42-600 {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
}
.fs-40-48-700 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

.fs-40-48-800 {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
}
.fs-48-58-700 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
}
.fs-36-43-500 {
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
}

.fs-36-43-600 {
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
}
.fs-12-14-400-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.fs-12-14-500 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.fs-16-19-400 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.fs-16-24-500 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.fs-16-19-500 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.fs-16-19-600 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.fs-20-72-700 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 72px;
}
.fs-12-143per-400 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 143%;
}
.fs-20-143per-400 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 143%;
}
.fs-20-24-500 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.fs-20-24-600 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.fs-24-29-500-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.fs-24-29-600 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.fs-24-29-700 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.fs-24-32-400 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}
.fs-24-36-700 {
  font-family: "Lato" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 29px !important;
}
.fs-48-72-700 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
}
.fs-18-22-500-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.fs-18-20-400-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
}
.fs-32-20-600-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 20px;
}
.fs-32-38-600 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
}
.fs-32-20-700-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 20px;
}
.fs-24-20-600-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 125%;
  color: #ffffff;
}
.fs-24-29-400-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
}
.fs-32-40-600-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}
.fs-32-75-700-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 75px;
}
.fs-40-60-700 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
}
.fs-18-27-400-lato {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}
.fs-16-24-400 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.fs-24-150-600 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
}
.fs-18-150-400 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

.font-lato {
  font-family: "Lato", "Open Sans";
}

.text-ft56-70 {
  font-size: 56px;
  line-height: 70px;
}

.text-ft32-40 {
  font-size: 32px;
  line-height: 40px;
}

.text-ft24-29 {
  font-size: 24px;
  line-height: 29px;
}

.text-ft40-56 {
  font-size: 40px;
  line-height: 56px;
}

.text-ft1vw-29 {
  font-size: 1.4vw;
  line-height: 29px;
}

.text-ft18-22 {
  font-size: 18px;
  line-height: 22px;
}

.text-ft18-150 {
  font-size: 18px;
  line-height: 150%;
}

.text-ft32-38 {
  font-size: 32px;
  line-height: 38px;
}

.text-ft28-140 {
  font-size: 28px;
  line-height: 140%;
}

.text-ft24-32 {
  font-size: 24px;
  line-height: 32px;
}

/*Font-color*/

.font-style-none {
  font-style: unset;
}

.fc-warm-grey {
  color: #999999;
}

.color-555 {
  color: #555;
}

.filter-white {
  -webkit-filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2176%)
    hue-rotate(132deg) brightness(113%) contrast(100%);
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2176%)
    hue-rotate(132deg) brightness(113%) contrast(100%);
}

.fc-7c7c7c {
  color: #7c7c7c !important;
}
.fc-7d7d7d {
  color: #7d7d7d !important;
}
.fc-dark-grey {
  color: #4d4d4d;
}

.fc-blue {
  color: #0052cc !important;
}

.fc-bluish {
  color: #1b5ea9 !important;
}

.fc-dark-slate-blue {
  color: #172b4d;
}
.fc-1D174A {
  color: #1d174a !important;
}

.fc-4c84ff {
  color: #4c84ff !important;
}

.fc-white {
  color: #ffffff !important;
}

.fc-grey {
  color: #b8b8b8 !important;
}

.fc-blue-grey {
  color: #5e6c84;
}

.fc-red {
  color: red;
}

.fc-brownish-grey {
  color: #666666;
}

.fc-dusky-blue {
  color: #465d85;
}

.fc-theme {
  color: #4c84ff !important;
}

.fc-black {
  color: #000000;
}

.fc-light-black {
  color: #181818;
}

.fc-dark-grey {
  color: #333333;
}

.fc-whitish-grey {
  color: #656565;
}

.fc-blackish {
  color: #3f3f3f;
}

.fc-dark-black {
  color: #171717;
}

.color-dark-grey {
  color: #333;
}

.text-darkGray-100 {
  color: #212121;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

/****************************************************************************************************
 ************************************** PADDING CSS START *******************************************
 ****************************************************************************************************/

.p-t-2 {
  padding-top: 2px !important;
}

.p-t-3 {
  padding-top: 3px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-b-52 {
  padding-bottom: 52px !important;
}
.p-t-247 {
  padding-top: 247px !important;
}

.p-t-xs {
  padding-top: 5px;
}

.p-r-xs {
  padding-right: 5px;
}

.p-b-2 {
  padding-bottom: 2px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.p-r-19 {
  padding-right: 19px;
}

.p-r-25 {
  padding-right: 25px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-16 {
  padding-top: 16px;
}
.p-t-34 {
  padding-top: 34px;
}

.p-b-xs {
  padding-bottom: 5px;
}
.p-b-54 {
  padding-bottom: 54px;
}
.p-t-68 {
  padding-top: 68px;
}
.p-b-68 {
  padding-bottom: 68px;
}

.p-b-70 {
  padding-bottom: 70px;
}
.p-t-80 {
  padding-top: 80px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-b-16 {
  padding-bottom: 16px;
}
.p-l-xs {
  padding-left: 5px;
}

.p-l-15 {
  padding-left: 15px;
}
.p-l-56 {
  padding-left: 56px;
}

.p-l-75 {
  padding-left: 75px;
}

.p-r-15 {
  padding-right: 15px;
}
.p-r-32 {
  padding-right: 32px;
}

.p-t-sm {
  padding-top: 10px;
}

.p-r-sm {
  padding-right: 10px;
}

.p-b-sm {
  padding-bottom: 10px;
}
.p-b-93 {
  padding-bottom: 93px;
}

.p-l-sm {
  padding-left: 10px;
}

.p-t {
  padding-top: 15px;
}
.p-t-0 {
  padding-top: 0px;
}
.p-r {
  padding-right: 15px;
}
.p-l-119 {
  padding-left: 119px;
}

.p-b {
  padding-bottom: 15px;
}

.p-l {
  padding-left: 15px;
}

.p-t-md {
  padding-top: 20px;
}
.p-l-24 {
  padding-left: 24px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.p-t-25 {
  padding-top: 25px;
}

.p-t-lg {
  padding-top: 30px;
}
.p-t-32 {
  padding-top: 32px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-t-0 {
  padding-top: 0px !important;
}

.p-l-60 {
  padding-left: 60px;
}

.grid-gap-align {
  grid-gap: 10% !important;
}
.grid-gap-align-item {
  grid-gap: 45px !important;
}
.grid-gap-align-div {
  grid-gap: 2% !important;
}
.min-w-110 {
  min-width: 110px;
}
.padd-top {
  padding-top: 56.25%;
}

@media screen and (min-width: 401px) {
  .p-r-md {
    padding-right: 20px;
  }
}

.p-b-md {
  padding-bottom: 20px;
}

.p-b-lg {
  padding-bottom: 30px;
}

.p-l-md {
  padding-left: 20px;
}
.p-l-42 {
  padding-left: 42px;
}
.p-r-42 {
  padding-right: 42px;
}

@media screen and (min-width: 401px) {
  .p-l-25 {
    padding-left: 25px;
  }
}

@media screen and (max-width: 400px) {
  .p-r-4 {
    padding-right: 4px;
  }
}

.p-xs {
  padding: 5px;
}

.p-0 {
  padding: 0 !important;
}

.p-sm {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}
.p-x-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.p-x-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.p-x-44 {
  padding-left: 44px;
  padding-right: 44px;
}
.p-x-72 {
  padding-left: 72px;
  padding-right: 72px;
}
.p-y-14 {
  padding-top: 14px;
  padding-bottom: 14px;
}

.p-md {
  padding: 20px;
}

.p-40 {
  padding: 40px;
}

.p-50 {
  padding: 50px;
}

.p-60 {
  padding: 60px;
}
.p-t-12 {
  padding-top: 12px;
}
.p-b-28 {
  padding-bottom: 28px;
}
.p-20 {
  padding: 20px;
}
.px-7 {
  padding-left: 7px;
  padding-right: 7px;
}
.py-14 {
  padding-top: 14px;
  padding-bottom: 14px;
}
.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.py-72 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.p-25 {
  padding: 25px;
}

.px-120 {
  padding-inline: 120px;
}
.px-16 {
  padding-inline: 16px;
}

.pt-48 {
  padding-top: 48px;
}

.pb-36 {
  padding-bottom: 36px;
}

/****************************************************************************************************
 ************************************** PADDING CSS END *********************************************
 ****************************************************************************************************/
.no-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
.no-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.scroll-div {
  overflow-x: auto;
}
.scroll-div::-webkit-scrollbar {
  width: 12px;
  background-color: #ffffff;
}

.scroll-div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4c84ff;
  cursor: pointer;
}

.intelligent-app {
  height: 102px;
  font-family: Lato;
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.96px;
  color: #171717;
}

.Ai {
  width: 31px;
  height: 29px;
  background-image: linear-gradient(to top, #51ac0e, #1859b2);
}

.Vizion {
  width: 107px;
  height: 29px;
  font-family: ArialRounded;
  font-size: 37px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1b5ea9;
}

.m-auto {
  margin: auto !important;
}

.drop-menu {
  margin-left: -40px;
}

.m-l-auto {
  margin-left: auto !important;
}

.m {
  margin-left: 0 !important;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l-lg {
  margin-left: 15px;
}

.m-l-23 {
  margin-left: 23px;
}

.m-l-24 {
  margin-left: 24px;
}

.m-l-200 {
  margin-left: 200px;
}

.m-l-250 {
  margin-left: 250px;
}

.m-l-260 {
  margin-left: 260px;
}

.m-l-350 {
  margin-left: 350px;
}
.m-l-340 {
  margin-left: 340px;
}
.m-l-450 {
  margin-left: 450px;
}

.m-l-20-per {
  margin-left: 20%;
}
.m-l-min3-per {
  margin-left: -3%;
}
.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px;
}
.m-l-16 {
  margin-left: 16px;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-23 {
  margin-left: 23px;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}
.m-l-45 {
  margin-left: 45px !important;
}

.m-l-60 {
  margin-left: 60px;
}
.m-l-90 {
  margin-left: 90px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.m-l-120 {
  margin-left: 120px !important;
}

.m-l-110 {
  margin-left: 110px !important;
}

.m-l-140 {
  margin-left: 140px !important;
}
.m-l-165 {
  margin-left: 165px !important;
}
.m-l-550 {
  margin-left: 550px !important;
}
.m-l-550-per {
  margin-left: 50% !important;
}
.m-l-650 {
  margin-left: 650px !important;
}
.m-l-135- {
  margin-left: -135px !important;
}
.m-l-300 {
  margin-left: 300px;
}
.m-l-90-per {
  margin-left: 90%;
}
.m-l-85-per {
  margin-left: 85%;
}
.m-l-650-per {
  margin-left: 80% !important;
}
.m-l-48-per {
  margin-left: 48% !important;
}
.m-l-28-per {
  margin-left: 5% !important;
}
.m-r-10-per {
  margin-right: 10% !important;
}
.m-r-8-per {
  margin-right: 8% !important;
}
.m-r-auto {
  margin-right: auto;
}

.m-r-230 {
  margin-right: 230px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-55 {
  margin-right: 55px;
}
.m-r-100 {
  margin-right: 100px;
}
.m-r-150 {
  margin-right: 150px;
}
.m-t-50-per {
  margin-top: 50%;
}
.m-t-10-per {
  margin-top: 10%;
}
.m-t-11 {
  margin-top: 11px;
}
.m-t-min5 {
  margin-top: -10px;
}

.m-t-min3 {
  margin-top: -1px;
}
.m-t-94 {
  margin-top: 94px;
}
.m-t-min {
  margin-top: -30px;
}
.m-t-min40 {
  margin-top: -40px;
}

m-t-min1 {
  margin-bottom: -15px;
}

.m-t-min2 {
  margin-top: -28px;
}
.m-t-min70 {
  margin-top: -70px;
}
.m-t-min3 {
  margin-top: -3px;
}
.m-t-min15 {
  margin-top: -15px;
}

.m-t-0 {
  margin-top: 0px;
}
.m-t-10per {
  margin-top: 10% !important;
}

.m-t-2 {
  margin-top: 2px;
}
.m-t-4 {
  margin-top: 4px;
}
.m-t-8 {
  margin-top: 8px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-sm {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px;
}
.m-t-16 {
  margin-top: 16px;
}
.m-t-17 {
  margin-top: 17px;
}
.m-t-18 {
  margin-top: 18px;
}

.m-t-20 {
  margin-top: 20px !important;
}
.m-t-24 {
  margin-top: 24px !important;
}
.m-t-26 {
  margin-top: 26px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-32 {
  margin-top: 32px !important;
}
.m-t-36 {
  margin-top: 36px !important;
}

.m-t-40 {
  margin-top: 40px;
}
.m-t-43 {
  margin-top: 43px;
}

.m-t-44 {
  margin-top: 44px;
}
.m-t-55 {
  margin-top: 55px !important;
}

.m-t-60 {
  margin-top: 60px;
}
.m-t-66 {
  margin-top: 66px;
}
.m-t-55 {
  margin-top: 55px;
}

.m-t-64 {
  margin-top: 64px;
}
.m-t-68 {
  margin-top: 68px;
}
.m-t-72 {
  margin-top: 72px;
}
.m-t-74 {
  margin-top: 74px;
}
.m-t-78 {
  margin-top: 78px;
}
.m-t-79 {
  margin-top: 79px;
}
.m-t-80 {
  margin-top: 80px;
}
.m-t-85 {
  margin-top: 85px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-t-108 {
  margin-top: 108px !important;
}
.m-t-120 {
  margin-top: 120px !important;
}
.m-t-134 {
  margin-top: 134px !important;
}
.m-t-80-imp {
  margin-top: 80px !important;
}
.m-t-150 {
  margin-top: 150px !important;
}

.m-t-160 {
  margin-top: 160px !important;
}

.m-t-170 {
  margin-top: 170px !important;
}
.m-t-174 {
  margin-top: 174px !important;
}
.m-t-180 {
  margin-top: 180px !important;
}

.m-t-200 {
  margin-top: 200px !important;
}

.m-t-250 {
  margin-top: 250px !important;
}

.m-t-300 {
  margin-top: 300px !important;
}

.m-t-450 {
  margin-top: 450px !important;
}

.m-t-500 {
  margin-top: 500px !important;
}

.m-t-600 {
  margin-top: 600px !important;
}

.m-b-0 {
  margin-bottom: 0;
}
.m-b-49 {
  margin-bottom: 49px;
}
.m-b-50 {
  margin-bottom: 50px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}
.m-b-18 {
  margin-bottom: 18px;
}

.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-24 {
  margin-bottom: 24px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-56 {
  margin-bottom: 56px;
}

.m-b-60 {
  margin-bottom: 60px;
}

.m-b-64 {
  margin-bottom: 64px;
}

.m-b-84 {
  margin-bottom: 84px;
}
.m-b-90 {
  margin-bottom: 90px;
}
.m-b-100 {
  margin-bottom: 100px;
}
.m-b-108 {
  margin-bottom: 108px !important;
}
.m-b-122 {
  margin-bottom: 122px;
}
.m-b-113 {
  margin-bottom: 113px !important;
}

.m-b-200 {
  margin-bottom: 200px !important;
}

.m-b-224 {
  margin-bottom: 224px !important;
}

.m-b-300 {
  margin-bottom: 300px !important;
}

.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.left-32-per {
  left: 32%;
}
.w-46-per {
  width: 46%;
}
.w-70 {
  width: 70% !important;
}

.w-90 {
  width: 90% !important;
}

.width-100px {
  width: 100px;
}
.width-100vw {
  width: 100px;
}
.width-100-per {
  width: 100%;
}
.height-20-per {
  height: 20%;
}

.b-10 {
  bottom: 10px;
}

.b-20 {
  bottom: 20px;
}

.b-30 {
  bottom: 30px;
}

.b-40 {
  bottom: 40px;
}
.object-container {
  object-fit: contain;
}

.home-img-h-w {
  width: 515.62px;
  height: 538px;
  /* width: 100%;
  height: auto; */
}
.h-80 {
  height: 80px;
}
.h-210 {
  height: 210px !important;
}

.h-216 {
  height: 216px !important;
}
.h-169 {
  height: 169px !important;
}

.height-10 {
  height: 10px;
}

.h-16 {
  height: 16px;
}

.h-30 {
  height: 30px;
}

.h-36 {
  height: 36px;
}
.h-38 {
  height: 38px;
}

.h-35 {
  height: 35px !important;
}

.h-50 {
  height: 50px !important;
}

.h-54 {
  height: 54px;
}

.h-159 {
  height: 159px;
}

.h-53 {
  height: 53px !important;
}
.h-54 {
  height: 54px !important;
}
.h-inherit {
  height: inherit;
}
.h-56 {
  height: 56px !important;
}

.h-60 {
  height: 60px;
}

.h-63 {
  height: 63px;
}

.h-75 {
  height: 75px;
}

.h-90 {
  height: 90px !important;
}
.h-96 {
  height: 96px;
}
.h-100-per {
  height: 100%;
}
.h-551 {
  height: 551px;
}

.h-140 {
  height: 140px;
}

.h-350 {
  height: 350px !important;
}
.h-368 {
  height: 368px !important;
}
.h-375 {
  height: 375px !important;
}
.h-531 {
  height: 531px !important;
}
.h-554 {
  height: 554px;
}
.h-654 {
  height: 654px;
}
.h-951 {
  height: 951px;
}
.h-100-m {
  height: 100px !important;
}

.max-h-30 {
  max-height: 30px !important;
}
.max-h-30 {
  max-height: 40px !important;
}
.max-h-50 {
  max-height: 100px !important;
}

.max-h-100 {
  max-height: 100px !important;
}
.max-h-169 {
  max-height: 169.15px;
}

.max-h-200 {
  max-height: 200px !important;
}
.max-h-81 {
  max-height: 81px !important;
}
.max-h-281 {
  max-height: 281px !important;
}

.max-h-360 {
  max-height: 360px;
}

.max-h-420 {
  max-height: 420px !important;
}

.height-100 {
  height: 100px;
}

.height-400 {
  height: 400px;
}
.w-8 {
  width: 8px;
}
.w-10 {
  width: 10px;
}
.h-10 {
  height: 10px;
}
.h-38{
  height: 38px;
}
.w-10-per {
  width: 10%;
}

.w-15-per {
  width: 15%;
}
.w-153 {
  width: 153px;
}
.w-30 {
  width: 30% !important;
}

.w-32 {
  width: 32% !important;
}

.w-35 {
  width: 35% !important;
}
.w-82 {
  width: 82px;
}
.h-82 {
  height: 82px;
}

.w-45 {
  width: 45% !important;
}

.w-100-per {
  width: 100% !important;
}

.w-50-per {
  width: 50% !important;
}

.w-54 {
  width: 54px !important;
}

.w-60-per {
  width: 60% !important;
}

.w-130 {
  width: 130px !important;
}
.w-191 {
  width: 191px !important;
}
.w-194 {
  width: 194px !important;
}
.w-197 {
  width: 197px !important;
}

.width-160 {
  width: 160px;
}

.w-80 {
  width: 80% !important;
}

.width-180 {
  width: 180px;
}

.width-184 {
  width: 184px;
}
.width-290 {
  width: 290px;
}
.w-419 {
  width: 419px;
}
.w-597 {
  width: 597px;
}
.w-610 {
  width: 610px !important;
}

.h-100-vh {
  height: 100vh;
}

.h-95vh {
  height: 95vh;
}

.p-b-25px {
  padding-bottom: 25px;
}

.p-b-93px {
  padding-bottom: 93px;
}
.h-81-per {
  height: 81%;
}

.h-100-per {
  height: 100%;
}

.height-33 {
  height: 33px;
}
.h-40 {
  height: 40px;
}
.h-42 {
  height: 42px;
}
.h-48 {
  height: 48px;
}
.h-180 {
  height: 180px;
}

.h-170 {
  height: 170px;
}
.h-194 {
  height: 194px;
}
.h-20-px {
  height: 20px;
}

.h-200 {
  height: 200px;
}

.h-220 {
  height: 220px;
}
.h-247 {
  height: 247px;
}
.h-330 {
  height: 330px;
}
.h-442 {
  height: 442px;
}

.h-460 {
  height: 460px;
}

.m-xs {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-auto {
  margin: auto;
}
.margin-18-auto {
  margin: 18px auto;
}

.m-t-xs {
  margin-top: 5px;
}
.m-0 {
  margin: 0px !important;
}

.m-0-auto {
  margin: 0 auto;
}

.m-t-25- {
  margin-top: -25px;
}

.m-t-35 {
  margin-top: 35px;
}

.margin-25 {
  margin: 25px;
}
.m-min50-per-top {
  margin-top: -50%;
}
.m-min56-per-top {
  margin-top: -56%;
}

.vertical-line {
  border-left: 2px solid black;
  height: 1000px;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}
.justify-around {
  justify-content: space-around;
}
.justify-between {
  justify-content: space-between;
}
.flex-1 {
  flex: 1 1;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.all-items-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*Background-color*/

.bg-blue {
  background-color: #0052cc !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-light-blue {
  background-color: #ccdcff !important;
}

.bg-theme {
  background-color: #4c84ff !important;
}
.bg-center {
  background-position: center;
}

.bg-creme {
  background-color: #fafbfc !important;
}

.bg-grey {
  background-color: #cccccc !important;
}

.bg-f8f8f8 {
  background-color: #f8f8f8 !important;
}

.bg-green {
  background-color: #3ab54a !important;
}
.bg-red {
  background-color: red !important;
}
.bg-light-grey {
  background-color: #fafafa;
}
.bg-light-voilet {
  background-color: #e5edff;
}
.bg-lighter-voilet {
  background-color: #e6edff;
}
.bg-theme-blue {
  background-color: #4c84ff;
}
.bg-lighter-blue {
  background-color: #6696ff;
}
.bg-lightBlue-10 {
  background: #f5f8ff;
}
.bg-lightBlue-20 {
  background-color: #3564b2;
}
.bg-lightBlue-30 {
  background-color: #3564b3;
}
.min-h-10 {
  min-height: 10px;
}
.min-h-494 {
  min-height: 494px;
}
.min-h-14 {
  min-height: 14px;
}
.min-h-30 {
  min-height: 30px;
}
.min-h-100 {
  min-height: 100px;
}
.min-h-654 {
  min-height: 654px;
}

.min-h-100vh {
  min-height: 100vh;
}
.min-h-100vw {
  min-height: 100vw;
}

.min-h-300 {
  min-height: 300px;
}

.h-80vh {
  min-height: 80vh;
}

/*Width-CSS*/

.outline-none {
  outline: none !important;
}

/*Width-CSS*/
.w-10-px {
  width: 10px;
}
.w-4-px {
  width: 4px !important;
}

.w-12-px {
  width: 12px;
}

.w-15-px {
  width: 15px;
}

.w-20-px {
  width: 20px;
}
.w-24 {
  width: 24px;
}
.w-30 {
  width: 30px;
}
.w-40 {
  width: 40px;
}
.w-56 {
  width: 56px;
}
.w-207 {
  width: 207px;
}
.w-240 {
  width: 240px;
}
.w-270 {
  width: 270px;
}

.w-277 {
  width: 277px;
}
.w-288 {
  width: 288px;
}
.w-307 {
  width: 307px;
}
.w-402 {
  width: 402px;
}

.w-30-px {
  width: 30px;
}
.w-36 {
  width: 36px;
}
.w-40-px {
  width: 40px;
}

.w-50-px {
  width: 50px;
}

.w-60-px {
  width: 60px;
}

.w-62px {
  width: 62px;
}

.w-75-px {
  width: 75px;
}

.w-80-px {
  width: 80px !important;
}

.w-100-px {
  width: 100px !important;
}

.w-110-px {
  width: 110px;
}
.w-112 {
  width: 112px;
}

.w-125 {
  width: 125px;
}

.w-140 {
  width: 140px !important;
}

.w-150 {
  width: 150px !important;
}
.w-154 {
  width: 154px !important;
}

.w-160 {
  width: 160px !important;
}
.w-177 {
  width: 177px !important;
}

.w-180 {
  width: 180px !important;
}

.w-200 {
  width: 200px;
}
.w-216 {
  width: 216px;
}
.w-219 {
  width: 219px;
}
.w-250 {
  width: 250px;
}

.w-300 {
  width: 300px;
}

.w-320 {
  width: 320px;
}

.w-330 {
  width: 330px;
}
.w-331 {
  width: 331px;
}

.w-350 {
  width: 350px;
}
.w-362 {
  width: 362px;
}
.w-381 {
  width: 381px;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px;
}

.w-480 {
  width: 480px;
}
.w-97-per {
  width: 97%;
}

.w-474 {
  width: 474px !important;
}

.w-497 {
  width: 497px !important;
}
.w-1040 {
  width: 1040px !important;
}

.w-403 {
  width: 403px;
}
.w-444 {
  width: 444px !important;
}
.w-493 {
  width: 493px;
}
.w-500 {
  width: 500px !important;
}
.w-506 {
  width: 506px !important;
}
.w-510 {
  width: 510px;
}
.w-534 {
  width: 534px;
}
.w-600 {
  width: 600px !important;
}
.w-101-per {
  width: 101% !important;
}
.w-700 {
  width: 700px !important;
}

.w-800 {
  width: 800px !important;
}

.w-1000 {
  width: 1000px !important;
}

.w-1100 {
  width: 1100px !important;
}

.w-1250 {
  width: 1250px !important;
}
.w-1300 {
  width: 1300px !important;
}
.w-2250 {
  width: 2250px !important;
}
.w-2260 {
  width: 2260px;
}
.w-1100-per {
  width: 80% !important;
}
.w-1250-per {
  width: 90% !important;
}

.w-75 {
  width: 75% !important;
}
.min-w-100vw {
  min-width: 100vw;
}

.max-w-75 {
  max-width: 75px !important;
}
.max-w-80 {
  max-width: 80px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}
.max-w-214 {
  max-width: 214px !important;
}
.max-w-249 {
  max-width: 249px;
}
.max-w-331 {
  max-width: 331px;
}
.max-w-400 {
  max-width: 400px;
}
.max-w-512 {
  max-width: 512px;
}
.max-w-250 {
  max-width: 250px !important;
}
.max-w-228 {
  max-width: 228px !important;
}

.max-w-300 {
  max-width: 300px !important;
}

.max-w-450 {
  max-width: 450px;
}

.max-w-500 {
  max-width: 500px;
}
.max-w-588 {
  max-width: 588px;
}
.max-w-620 {
  max-width: 620px;
}

.max-w-683 {
  max-width: 683px;
}

.max-w-805 {
  max-width: 805px;
}

.max-w-911 {
  max-width: 911px;
}

.max-w-510 {
  max-width: 510px;
}

.max-w-686 {
  max-width: 686px;
}
.max-w-1038 {
  max-width: 1038px;
}
.max-w-1280 {
  max-width: 1280px;
}
.max-w-1920 {
  max-width: 1920px;
}
.w-40 {
  width: 40% !important;
}

.w-65 {
  width: 65% !important;
}
.w-75 {
  width: 70%;
}

.w-15 {
  width: 15% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}
.h-8 {
  height: 8px;
}

.h-15 {
  height: 15px;
}
.h-24 {
  height: 24px;
}
.h-28 {
  height: 28px;
}
.h-30 {
  height: 30px;
}

.h-30 {
  height: 48px;
}
.h-153 {
  height: 153px !important;
}
.h-243 {
  height: 243px;
}

.h-400 {
  height: 400px;
}
.height-400 {
  height: 400px !important;
}
.h-500 {
  height: 500px;
}
.h-600 {
  height: 600px;
}
.h-45 {
  height: 45px;
}

.h-150 {
  height: 150px;
}

.z-index-100 {
  z-index: 100;
}
.z-index-102{
  z-index: 102;
}

.table-data {
  vertical-align: middle;
  padding: 10px;
  font-size: 15px;
  line-height: 1.4;
}

.dot {
  height: 7px;
  width: 7px;
  background-color: #3f3f3f;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 10px;
}

.footer {
  padding: 15px;
  position: fixed;
  background-color: white;
  border: 1px solid #e0e0e2;
  left: 0;
  bottom: 0;
  width: 100%;
}
.footer2 {
  position: relative;
  background-color: black;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 47%;
}
.footer3 {
  padding: 15px;
  position: relative;
  background-color: #4c84ff;
  border: 1px solid #e0e0e2;
  left: 0;
  bottom: 0;
  width: 100%;
}

.max-w-200 {
  max-width: 200px;
}
.blue-button-1 {
  background-color: #4c84ff !important;
}
.blue-button-1:hover {
  background: #3564b2 !important;
}
.fc-lightBlue30 {
  color: #3564b3;
}

.fc-black-hover:hover {
  color: black !important;
}

.fc-black-hover:not(:hover) {
  color: black !important;
}

.text-transform-capitalize {
  text-transform: capitalize !important;
}
.text-transform-uppercase {
  text-transform: uppercase !important;
}
.text-transform-none {
  text-transform: none !important;
}

.text-align-left {
  text-align: left !important;
}

.input-border {
  border: solid 1px #dfe1e6;
  border-radius: 2px;
  background-color: #fafbfc;
}

.checkbox-border {
  border-radius: 2px;
  border: solid 1px #b9bbc2;
  background-color: #ffffff;
}

.app-logo {
  width: 194px;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #b0b0b0 !important;
  margin: 10px 10px !important;
  position: absolute;
  top: 40% !important;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px !important;
  height: 35px !important;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.video-react .video-react-big-play-button {
  top: 40% !important;
  left: 45% !important;
}
.btn-styled {
  height: 40px;
  width: 140px;
  text-align: center;
  border-color: #4c84ff;
}
.btn-styled-2 {
  height: 40px;
  width: 240px;
  text-align: center;
  border-color: #4c84ff;
}
.btn-styled-3 {
  text-align: center;
  border-color: #4c84ff;
}
.btn-styled-4 {
  height: 40px;
  width: 140px;
  text-align: center;
  border: 1px solid white;
}

.blue-styled-btn {
  display: grid;
  outline: none;
  border: none;
  place-content: center;
  text-align: center;
  background-color: #4c84ff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: "Lato", "Open Sans";
  border-radius: 25px;
  height: 42px;
}

.blue-styled-btn:focus {
  outline: none;
}

.outline-none-focus:focus {
  outline: none;
}

.btn-outline-none,
.btn-outline-none:focus {
  outline: none;
}

.app-info {
  min-width: 110px;
  text-align: right;
  margin-right: 10px !important;
}

.home-container {
  max-width: 1920px !important;
  margin: auto;
}

.iframe-b-1 {
  border: 1px solid #dfe1e6 !important;
}

.iframe-b-0 {
  border: 0;
}

.border-1 {
  border-width: 1px;
  border-style: solid;
}

.border-lightBlue-10 {
  border-color: #ccdcff;
}
.border-black-10 {
  border-color: #000000;
}

.tab-content {
  min-height: 100%;
  width: 100%;
}

.madInputSetting.nav-tabs .nav-link {
  color: #333333 !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.selected-app {
  color: white !important;
  border: solid 1px #4c84ff;
  border-radius: 2px;
  background-color: #4c84ff !important;
}

.app {
  color: #181818 !important;
  border: solid 1px #dfe1e6;
  border-radius: 2px;
  background-color: #fafbfc !important;
}

.profile-img {
  width: 30px;
  border-radius: 5px;
}

.profile-dropdown {
  display: flex;
  align-items: center;
  color: #333333 !important;
  border: 0 !important;
  background: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.profile-dropdown::after {
  color: black;
  border-top: 0.35em solid;
  border-right: 0.35em solid transparent;
  border-left: 0.35em solid transparent;
}

.headerComponent {
  top: 18px !important;
  left: 150px !important;
}

.h-40 {
  height: 40px;
}

.h-45 {
  height: 45px;
}

.h-130 {
  height: 130px;
}
.h-120 {
  height: 120px;
}
.jc-space-between {
  justify-content: space-between;
}
.jc-space-evenly {
  justify-content: space-evenly;
}
.jc-space-around {
  justify-content: space-around;
}
.b-none {
  background: none;
}

.box-shadow-none {
  box-shadow: none !important;
}
.shadow-white {
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.1);
}
.shadow-blue-1 {
  box-shadow: 4px 4px 8px 4px rgba(76, 132, 255, 0.15);
}

.align-item-center {
  align-items: center;
}

.bg-none {
  background: none !important;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  display: none;
}

/* @media screen and (max-width: 768px) {
    .hide-left-menu {
        display: none !important;
    }
    .mobile-menu {
        display: block !important;
    }
} */

.dropdown-menu-top {
  text-align: center;
  top: 5px !important;
  left: 30px !important;
}

.overflow-auto {
  overflow: auto !important;
}
.overflow-hideen {
  overflow: hidden !important;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.align-item-center {
  align-items: center;
}

.css-1rpz77w {
  margin: auto;
}

.swal-button {
  background-color: #4c84ff !important;
}

.swal-title:not(:last-child) {
  margin-bottom: 0;
}

.swal-text {
  text-align: center !important;
  margin-left: 55px;
  margin-right: 55px;
  margin-top: 5px;
}

.position-abs {
  position: absolute;
}
.position-rel {
  position: relative;
}
.left-0 {
  left: 0px;
}
.right-0 {
  right: 0px;
}

.right-15 {
  right: 15px;
}
.top-0 {
  top: 0px;
}
.top-7 {
  top: 7px;
}
.top-72 {
  top: 72px;
}

.text-align-center {
  text-align: center;
}

.css-0 {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
}

.left-45 {
  left: 45% !important;
}

a:hover {
  color: #000000 !important;
}

.help-text {
  bottom: 0px;
  position: inherit;
  padding: 5px;
}

label:hover {
  color: black !important;
}
.work-image {
  width: auto;
  height: auto;
}
.word-break {
  word-break: break-word;
}

.logo-container-home {
  border: 1.5px solid #4c84ff;
  border-radius: 12px;
}

.h-fit {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.button-text-transform {
  text-transform: none !important;
}

.developer-form-container {
  border: 1.5px solid #4c84ff;
  border-radius: 40px;
}

.form-label {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #264780;
}

.form-input {
  border: 1px solid #b3b3b3;
  border-radius: 30px;
  outline: none;
  height: 48px;
}

.cross-icon {
  top: 61px;
  right: 13%;
}

.resize-none {
  resize: none;
}
.dialog-scrollbar {
  scrollbar-width: auto;
  scrollbar-color: #7f7f7f #d0d5dd;
}

.dialog-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.dialog-scrollbar::-webkit-scrollbar-track {
  background: #d0d5dd;
}

.dialog-scrollbar::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 10px;
  border: 0px solid #080411;
}

.-rotate-180 {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  transition-duration: 100ms;
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition-duration: 100ms;
}

.green-dot {
  width: 50px;
  height: 50px;
  background: greenyellow;
  margin-left: 120px;
  border-radius: 50%;
}
.color-light-blue {
  color: #4c84ff;
}
.bg-light-blue {
  background-color: #4c84ff;
}
.bg-light-blue-20 {
  background-color: #4c84ff;
}
.button-style {
  border: none;
  outline: none;
  border-radius: 2px;
}
.bg-off-white {
  background-color: #f7f7f7;
}
.h-27 {
  height: 27px;
}
.text-offgrey {
  color: #ababab;
}
.text-grey {
  color: #7f7f7f;
}
.decoration-none {
  text-decoration: none;
}
.pointer {
  cursor: pointer;
}
.gap-10 {
  grid-gap: 10px;
  gap: 10px;
}
.gap-14 {
  grid-gap: 14px;
  gap: 14px;
}
.gap-16 {
  grid-gap: 16px;
  gap: 16px;
}
.gap-17 {
  grid-gap: 17px;
  gap: 17px;
}
.gap-18 {
  grid-gap: 18px;
  gap: 18px;
}
.gap-19 {
  grid-gap: 19px;
  gap: 19px;
}
.gap-23 {
  grid-gap: 23px;
  gap: 23px;
}
.gap-24 {
  grid-gap: 24px;
  gap: 24px;
}
.gap-26 {
  grid-gap: 26px;
  gap: 26px;
}
.gap-34 {
  grid-gap: 34px;
  gap: 34px;
}
.gap-36 {
  grid-gap: 36px;
  gap: 36px;
}
.gap-40 {
  grid-gap: 40px;
  gap: 40px;
}
.gap-56 {
  grid-gap: 56px;
  gap: 56px;
}
.gap-59 {
  grid-gap: 59px;
  gap: 59px;
}
.gap-84 {
  grid-gap: 84px;
  gap: 84px;
}
.gap-85 {
  grid-gap: 85px;
  gap: 85px;
}
.gap-93 {
  grid-gap: 93px;
  gap: 93px;
}
.gap-94 {
  grid-gap: 94px;
  gap: 94px;
}
.gap-110 {
  grid-gap: 110px;
  gap: 110px;
}
.gap-174 {
  grid-gap: 174px;
  gap: 174px;
}
.gap-267 {
  grid-gap: 267px;
  gap: 267px;
}
.gap-150 {
  grid-gap: 150px;
  gap: 150px;
}
.column-gap-15 {
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.column-gap-55 {
  grid-column-gap: 55px;
  -webkit-column-gap: 55px;
          column-gap: 55px;
}
/* .background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(
      90deg,
      #fefefe 0%,
      rgba(231, 239, 253, 0.71) 100%
    ),
    url(/images/HomeCompBackgroundImage.png);
} */
.color-dark-blue {
  color: #3564b2;
}
.feature-block {
  display: inline-block;
  width: 25rem;
  height: 23.5rem;
  background-color: #3564b2;
  color: white;
  position: relative;
}
.feature {
  position: absolute;
  top: 2.5rem;
  left: 1rem;
}
.featured-para {
  position: absolute;
  top: 6rem;
  left: 1rem;
}
.max-w-190 {
  max-width: 190px !important;
}
.max-w-247 {
  max-width: 247px !important;
}
.bg-black {
  background-color: #172b4d !important;
}
.max-h-110 {
  max-height: 110px;
}
.max-h-451 {
  max-height: 451px;
}
.vertical-line-work {
  border-left: 1px solid #d9d9d9;
  height: 260px;
}
.horizontal-work {
  border: 2px solid #b6b6b6;
}
.horizontal-line {
  border: 2px solid #ccdcff;
}
.vertical-line {
  border: 2px solid #ccdcff;
  height: 50px;
}

.border-darkBlue-50 {
  border: 1px solid #264780;
}

.vertical-line-lightBlue {
  width: 4px;
  background-color: #ccdcff;
}

.work-number-container {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  padding: 7px;
}

.work-number-div {
  background: #172b4d;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.work-number {
  color: #4c84ff;
}

.grid-place-items-center {
  display: grid;
  place-items: center;
}
.white-space-no-wrap {
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 1px !important;
}

#registerPage .react-datepicker__navigation--next--with-time,
#registerPage .react-datepicker__navigation--previous,
#registerPage .react-datepicker__month-container {
  display: none;
}

.MuiInputBase-root {
  height: 40px !important;
}

.MuiInput-underline:after,
.MuiInput-underline:before {
  display: none !important;
}

.MuiSelect-select:focus {
  background: none !important;
}

.flex-wrap-home {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

#frame > div > div > div {
  display: flex;
  justify-content: center;
}

.position-hidden {
  position: absolute;
  z-index: -1;
}

.user-device-ht {
  height: 503px;
}

.terms-privacy-footer {
  position: relative;
  top: -11px;
}
.flex {
  display: flex;
}

.flex-direction-row {
  flex-direction: row !important;
}
.flex-direction-column {
  flex-direction: column !important;
}
.flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}
.t-a-l {
  text-align: left;
}

.t-a-c {
  text-align: center;
}

.featured-apps-button:hover {
  background: #4c84ff !important;
}

.featured-apps-container {
  top: 20%;
}

.right-side-dot {
  top: -9%;
  right: 0px;
}

.slight-div-square {
  width: 243.44px;
  height: 243.44px;
  background: #ffffff;
  box-shadow: 2.70491px 2.70491px 5.40982px 2.70491px rgba(102, 150, 255, 0.2);
  border-radius: 21.6393px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.text-shadow-4b2 {
  text-shadow: 2px 0 #3564b2, -2px 0 #3564b2, 0 2px #3564b2, 0 -2px #3564b2,
    1px 1px #3564b2, -1px -1px #3564b2, 1px -1px #3564b2, -1px 1px #3564b2;
}
.text-how-its-work {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 77px;
  /* identical to box height */
  color: white;
  text-shadow: 2px 0 #26477f, -2px 0 #26477f, 0 2px #26477f, 0 -2px #26477f,
    1px 1px #26477f, -1px -1px #26477f, 1px -1px #26477f, -1px 1px #26477f;
  left: 52%;
  top: -22%;
}

.base-container {
  max-width: 96%;
  margin: auto;
}
.container-current-apps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  place-items: start;
}
.small-dot {
  left: -14%;
}
.feature-card {
  cursor: pointer;
}

.feature-card:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  transition: all 0.1s ease 0s;
  border-radius: 10px;
}

.talk-ai-expert-button:hover {
  background-color: #6696ff !important;
}

.input-autofill-camera:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.faq-container:hover {
  background: #f5f8ff;
}

.vertical-line-trial {
  border-left: 1px solid #808080;
  height: 100%;
}
.vertical-line-right {
  border-right: 1px solid #808080;
  height: 100%;
}
.-top-22 {
  top: -22px;
}
.bottom-75 {
  bottom: 75px;
}
.bottom-1 {
  bottom: 1px;
}
.-left-12 {
  left: -12px;
}
.bg-light-blue-50 {
  background: linear-gradient(180deg, #f5f8ff 70%, #fff 70%);
}
.footer-component-grid {
  grid-template-columns: 88px 230px 260px;
}
.policy-container {
  grid-template-columns: 402px auto;
}
.pop-up-footer {
  top: 10px;
  right: 15px;
  cursor: pointer;
}
.footer-dialog {
  padding-top: 0px !important;
}
.animate {
  width: 1800%;
  -webkit-animation: marquee 20s linear infinite;
          animation: marquee 20s linear infinite;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.animateTwo {
  width: 1800%;
  -webkit-animation: marqueeTwo 20s linear infinite;
          animation: marqueeTwo 20s linear infinite;
  top: 50%;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.logo a {
  width: 287px;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.image-about {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  cursor: pointer;
}
.image-about:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

@-webkit-keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -90%;
  }
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -90%;
  }
}
@-webkit-keyframes marqueeTwo {
  0% {
    left: -90%;
  }
  100% {
    left: 0%;
  }
}
@keyframes marqueeTwo {
  0% {
    left: -90%;
  }
  100% {
    left: 0%;
  }
}
.flex-basis-50 {
  flex-basis: 50%;
}
.main-div {
  width: 419px;
  height: 494px;
  position: relative;
}
.benefit-main-div {
  width: 685px;
  height: 385px;
  position: relative;
}
.base-div {
  border: none;
  bottom: 0;
  display: block;
  height: 0;
  left: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
}
.grid-auto-fit {
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
}

.custom-carousel .carousel-indicators {
  visibility: hidden;
  opacity: 0;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 4px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.work-container div:nth-child(5) {
  max-width: 67%;
}

.react-multi-carousel-list {
  padding-bottom: 10px;
  padding-top: 10px;
}

.sticky-css {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 101;
}
.fs-28-34-700 {
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 34px !important;
}

.top-49{
  top: 49px;
}

@media screen and (min-width: 1600px) {
  .base-container {
    max-width: 85%;
    margin: auto;
  }
  .xxl-px-120 {
    padding-inline: 120px;
  }
  .xxl-flex-basis-36 {
    flex-basis: 36%;
  }
}
@media screen and (min-width: 1400px) {
  .hide-left-menu {
    display: flex !important;
  }

  .p-3-desktop {
    padding: 1rem !important;
  }

  .mobile-menu {
    display: block !important;
  }

  .mr-3-m {
    margin-right: 1rem !important;
  }

  .m-l-50 {
    margin-left: 50px !important;
  }

  .h-350-desktop {
    height: 350px !important;
  }

  .m-t-40-desktop {
    margin-top: 40px !important;
  }

  .pt-5-desktop {
    padding-top: 3rem !important;
  }

  .h-200-desktop {
    height: 200px !important;
  }

  .mr-4-desktop {
    margin-right: 20px !important;
  }

  .w-600-desktop {
    width: 600px !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 320px) {
  .m-t-600-m {
    margin-top: 600px;
  }
  .base-container {
    padding-inline: 20px;
  }

  /* .m-b-min-250 {
        margin-bottom: -250px !important;
    } */

  .m-b-90-m {
    margin-bottom: 90px;
  }

  .w-340-m- {
    width: 340px;
  }

  .h-250-m- {
    height: 250px;
  }

  .w-100-m {
    width: 100px !important;
  }

  .m-t-min-25-m {
    margin-top: -25px !important;
  }

  .user-device {
    height: 58px;
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    color: #000000;
    padding: 17px;
  }
  .hamburger-menu-first {
    width: 12px;
    height: 2px;
    background-color: #000000;
    margin: 3px;
  }
  .hamburger-menu {
    width: 23px;
    height: 2px;
    background-color: #000000;
    margin: 3px;
  }

  .m-b-80-m- {
    margin-bottom: 80px !important;
  }

  .m-b-50-m- {
    margin-bottom: 50px !important;
  }

  .h-150-px-m {
    height: 150px !important;
  }

  .l-h-2-m {
    line-height: 2rem !important;
  }

  .p-0-m {
    padding: 0 !important;
  }

  .align-items-center-m {
    align-items: center !important;
  }

  .hide-left-menu {
    display: none !important;
  }

  .display-flex-m {
    display: flex !important;
  }

  .display-none-m {
    display: none !important;
  }

  .flex-wrap-m {
    flex-wrap: wrap !important;
  }

  .flex-wrap-reverse-m {
    flex-wrap: wrap-reverse !important;
  }

  .jc-center-m {
    justify-content: center !important;
  }

  .mr-3-m {
    margin-right: 0px !important;
  }

  .m-r-sm-m {
    margin-right: 10px !important;
  }

  .m-r-xs-m {
    margin-right: 5px !important;
  }

  .m-l-xs-m {
    margin-left: 5px !important;
  }

  .m-l-0-m {
    margin-left: 0 !important;
  }

  .m-l-sm-m {
    margin-left: 10px !important;
  }

  .m-l-50 {
    margin-left: 0px !important;
  }

  .jc-center-m {
    justify-content: center !important;
  }

  .w-100-per-m {
    width: 100% !important;
  }

  .p-3-desktop {
    padding: 0 !important;
  }

  .p-l-sm-m {
    padding-left: 10px !important;
  }

  .m-t-20-m {
    margin-top: 20px !important;
  }

  .w-350-m {
    width: 350px !important;
  }

  .pt-5-desktop {
    padding-top: 0 !important;
  }

  .m-t-sm-m {
    margin-top: 10px !important;
  }

  .m-t-30-m {
    margin-top: 30px !important;
  }

  .align-items-center-m {
    align-items: center !important;
  }

  .p-l-xs-m {
    padding-left: 5px !important;
  }

  .p-r-xs-m {
    padding-right: 5px !important;
  }

  .margin-auto-m {
    margin: auto !important;
  }

  .h-25px-m {
    height: 25px !important;
  }

  .h-fit-content-m {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .l-h-3-m {
    line-height: 2.5rem !important;
  }

  .w-95px-m {
    width: 95px !important;
  }

  .w-75-per-m {
    width: 75% !important;
  }

  .w-25-per-m {
    width: 25% !important;
  }

  .h-35px-m {
    height: 35px !important;
  }

  .h-110px-m {
    height: 110px !important;
  }

  .w-100-px-m {
    width: 100px !important;
  }

  .m-t-0-m {
    margin-top: 0 !important;
  }

  .p-l-15-m {
    padding-left: 15px;
  }

  .p-r-15-m {
    padding-right: 15px;
  }

  .position-hidden-m {
    position: absolute;
    z-index: -1;
  }

  .position-relative-m {
    position: relative;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 767px) {
  .w-140 {
    width: 140px !important;
  }

  .m-r-15-m {
    margin-right: 15px !important;
  }
  .display-flex-tab {
    display: flex !important;
  }

  .margin-10-desktop {
    margin: 10px !important;
  }

  .mt-20-t {
    margin-top: 20px !important;
  }
  .home-img-h-w {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .m-b-50 {
    margin-bottom: 50px !important;
  }
  .xl-px-70 {
    padding-inline: 70px;
  }

  .w-340 {
    width: 340px !important;
  }
  .w-34 {
    width: 34px !important;
  }

  .h-250 {
    height: 250px !important;
  }

  .h-350-xl {
    height: 350px;
  }

  .border-input {
    border: solid 1px #dfe1e6;
    border-radius: 2px;
    background-color: #fafbfc;
  }

  .display-none-desktop {
    display: none !important;
  }

  .fs-m-32 {
    font-size: 32px;
  }

  .w-m-600 {
    width: 600px;
  }

  .user-device {
    width: 691px;
    height: 39px;
    font-family: Lato;
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
  /* .m-t-140-m {
        margin-top: 140px !important;
    } */
  /* .how-its-work-container {
    margin-top: 1128px !important;
  } */

  .w-100-percant-m {
    width: 100%;
  }
  .t-a-c-m {
    text-align: center;
  }
  .h-38-m {
    height: 38px;
  }

  .fs-8-10-600-m {
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
  }
  .fs-12-10-600-m {
    font-weight: 600;
    font-size: 12px;
    line-height: 10px;
  }
  .fs-24-36-700-m {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 29px !important;
  }
  .home-img-h-w {
    width: 275px !important;
    height: 286.94px !important;
  }
  .fs-16-24-500-m {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .fs-12-14-500-m {
    font-family: "Lato";
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
  .fs-20-72-700-m {
    font-family: "Lato" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 72px !important;
  }
  .fs-12-143per-400-m {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143%;
  }

  .max-w-228-m {
    max-width: 228px !important;
  }
  .display-mobile-none-m {
    display: none !important;
  }
  .display-mobile-flex-m {
    display: flex !important;
  }
  .m-auto-m {
    margin: auto !important;
  }
  .w-auto-m {
    width: auto !important;
  }
  .w-340-m {
    width: 340px;
  }

  .fs-28-m {
    font-size: 28px !important;
  }

  .p-t-20-m {
    padding-top: 20px !important;
  }

  .w-327-px-m {
    width: 327px !important;
  }

  .fs-m-17 {
    font-size: 17px;
  }

  .m-b-10-m {
    margin-bottom: 10px;
  }

  .m-b-20-m {
    margin-bottom: 20px;
  }

  .m-t-min-10-m {
    margin-top: -10px !important;
  }

  .unstyled-button {
    border: none;
    padding: 0;
    background: none;
  }

  .user-device-m {
    height: 58px;
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    color: #000000;
    padding: 17px;
  }
  .font-f-lato {
    font-family: Lato;
  }

  .w-250-m {
    width: 250px !important;
  }

  .m-b-50-m {
    margin-bottom: 50px !important;
  }

  .w-369-px-m {
    width: 360px !important;
  }

  .margin-t-0-m {
    margin-top: 0px !important;
  }

  .m-t-min-65-m {
    margin-top: -75px;
  }
  .m-t-106-m {
    margin-top: 106px !important;
  }
  .m-t-125-m {
    margin-top: 125px;
  }

  .m-t-250-m {
    margin-top: 250px;
  }
  .p-l-0-m {
    padding-left: 0px;
  }

  .p-l-130-m {
    padding-left: 130px;
  }
  .p-l-50-m {
    padding-left: 50px;
  }
  .p-l-27-m {
    padding-left: 27px;
  }
  .m-l-120 {
    margin-left: 120px !important;
  }
  .m-r-120 {
    margin-right: 120px !important;
  }
  .m-t-48-m {
    margin-top: 48px;
  }

  .bg-f9f9f9-m {
    background-color: #f9f9f9;
  }

  .h-230-m {
    height: 230px;
  }

  .p-sm-m {
    padding: 10px !important;
  }

  .t-a-justify-m {
    text-align: justify;
  }

  .p-5-m {
    padding: 5px;
  }

  .m-t-264-m {
    margin-top: 264px;
  }

  .w-210-m {
    width: 210px;
  }

  .fs-24-m {
    font-size: 24px !important;
  }

  .p-10-m {
    padding: 10px !important;
  }

  .fs-26-m {
    font-size: 26px !important;
  }

  .h-250-m {
    height: 250px;
  }
  .m-t-20-m {
    margin-top: 20px;
  }

  .p-15-m {
    padding: 15px;
  }

  .w-90-per-m {
    width: 90% !important;
  }
  .h-216-m {
    height: 216px;
  }
  .w-112-m {
    width: 112px;
  }
  .h-80-m {
    height: 80px;
  }
  .m-l-18-m {
    margin-left: 18px;
  }
  .gap-16-m {
    grid-gap: 16px;
    gap: 16px;
  }
  .gap-40-m {
    grid-gap: 40px;
    gap: 40px;
  }
  .gap-80-m {
    grid-gap: 80px;
    gap: 80px;
  }
  .gap-110-m {
    grid-gap: 110px;
    gap: 110px;
  }
  .gap-134-m {
    grid-gap: 134px;
    gap: 134px;
  }
  .gap-200-m {
    grid-gap: 200px;
    gap: 200px;
  }
  .gap-270-m {
    grid-gap: 270px;
    gap: 270px;
  }
  .m-b-224-m {
    margin-bottom: 224px !important;
  }
  .work-image {
    width: 115.16px;
    height: 95px;
    margin: auto;
  }
  .work-number-div {
    width: 28.18px;
    height: 28.18px;
  }
  .vertical-line-work {
    height: 160px;
  }

  .overflow-x-auto-m {
    overflow-x: auto;
  }
  .feature-block {
    margin-left: 0px !important;
  }
  .flex-direction-row-m {
    flex-direction: row !important;
  }
  .flex-direction-column-m {
    flex-direction: column !important;
  }
  .flex-direction-row-reverse-m {
    flex-direction: row-reverse !important;
  }
  .flex-direction-column-reverse-m {
    flex-direction: column-reverse !important;
  }
  .justify-content-center-m {
    justify-content: center !important;
  }

  @media screen and (device-aspect-ratio: 40/71) {
    .m-l-min-10-m {
      margin-left: -10px;
    }

    .m-l-min-12-m {
      margin-left: -12px;
    }

    .overflow-x-auto-m- {
      overflow: auto;
    }

    .w-360-m {
      width: 360px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .display-mobile-none {
    display: none !important;
  }

  .flex-wrap-home {
    flex-wrap: wrap !important;
  }

  .home-img-h-w {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1024px) {
  .how-its-work-container {
    margin-top: 630px !important;
  }
  .lg-flex-col-reverse {
    flex-direction: column-reverse !important;
  }
  .lg-flex-col {
    flex-direction: column !important;
  }
  .lg-justify-center {
    justify-content: center !important;
  }
  .lg-justify-between {
    justify-content: space-between !important;
  }
  .lg-flex-wrap {
    flex-wrap: wrap !important;
  }
  .lg-w-full {
    width: 100% !important;
  }
  .lg-w-54 {
    width: 54px;
  }
  .lg-px-0 {
    padding-inline: 0px;
  }
  .lg-grid {
    display: grid;
  }
  .lg-w-400 {
    width: 400px;
  }
  .lg-h-193{
    height: 193px;
  }
  .lg-h-450 {
    height: 450px;
  }
  .lg-place-items-center {
    place-items: center;
  }
  .lg-m-t-20 {
    margin-top: 20px !important;
  }
  .lg-text-center {
    text-align: center;
  }
  .lg-mx-auto {
    margin-inline: auto;
  }
  .lg-items-center {
    align-items: center;
  }
  .lg-flex-col {
    flex-direction: column;
  }
  .lg-gap-y-14 {
    grid-row-gap: 14px;
    row-gap: 14px;
  }

  .lg-grid-cols-1 {
    grid-template-columns: 1fr;
  }
  .lg-grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .lg-grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .lg-h-auto {
    height: auto !important;
  }
  .lg-max-w-none {
    max-width: none;
  }
  .lg-max-w-588 {
    max-width: 588px;
  }
  .lg-hidden {
    display: none !important;
  }
  .lg-block {
    display: block !important;
  }
  .lg-mt-16 {
    margin-top: 16px;
  }
  .lg-bg-white {
    background-color: white;
  }
  .lg-mt-0 {
    margin-top: 0px;
  }
  .lg-ml-0 {
    margin-left: 0px !important;
  }
  .lg-fs-12-14-400 {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
  .lg-fs-24-29-700 {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 29px !important;
  }
  .no-scroll-div {
    overflow-x: hidden !important;
  }
  .lg-fs-20-72-700 {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 72px;
  }
  .slight-div-square {
    width: 180.44px;
    height: 180.44px;
    background: #ffffff;
    box-shadow: 2.70491px 2.70491px 5.40982px 2.70491px rgba(102, 150, 255, 0.2);
    border-radius: 21.6393px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .how-it-work-image {
    width: 700px !important;
  }
  .gap-85-div {
    grid-gap: 85px !important;
    gap: 85px !important;
  }
  .lg-top-0 {
    top: 0px;
  }
  .lg-w-250 {
    width: 250px !important;
  }
  .lg-h-250 {
    height: 250px;
  }
}

@media (max-width: 767px) {
  .base-container {
    max-width: 100%;
  }
  .md-justify-center {
    justify-content: center !important;
  }
  .md-justify-between {
    justify-content: space-between !important;
  }
  .md-hide {
    display: none;
  }
  .md-flex {
    display: flex;
  }
  .md-block {
    display: block !important;
  }
  .md-grid {
    display: grid !important;
  }
  .md-p-t-40 {
    padding-top: 40px;
  }
  .md-min-h-30 {
    min-height: 30px;
  }
  .md-gap-24 {
    grid-gap: 24px;
    gap: 24px;
  }
  .md-gap-0 {
    grid-gap: 0px;
    gap: 0px;
  }
  .md-gap-16 {
    grid-gap: 16px;
    gap: 16px;
  }
  .md-grid-cols-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .md-grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .md-place-items-center {
    place-items: center;
  }
  .md-h-auto {
    height: auto !important;
  }
  .md-w-310 {
    width: 310px;
  }
  .md-h-237 {
    height: 237px !important;
  }
  .md-top-15 {
    top: 15px;
  }
  .md-left-24 {
    left: 24px;
  }
  .md-h-68 {
    height: 68px;
  }
  .md-h-25-per {
    height: 25% !important;
  }
  .md-h-350 {
    height: 350px;
  }
  .md-h-176 {
    height: 176px !important;
  }
  .md-px-8 {
    padding-inline: 8px !important;
  }
  .md-p-t-8 {
    padding-top: 8px;
  }
  .md-p-b-16 {
    padding-bottom: 16px;
  }
  .md-m-t-6 {
    margin-top: 6px;
  }
  .md-m-t-10 {
    margin-top: 10px;
  }
  .md-m-t-14 {
    margin-top: 14px;
  }
  .md-max-w-105 {
    max-width: 105px;
  }
  .md-max-w-128 {
    max-width: 128px;
  }
  .md-max-w-320 {
    max-width: 320px;
  }
  .md-px-28 {
    padding-inline: 28px;
  }
  .md-px-24 {
    padding-inline: 24px !important;
  }
  .md-mt-28 {
    margin-top: 28px;
  }
  .md-mt-0 {
    margin-top: 0px;
  }
  .md-text-ft24-30 {
    font-size: 24px;
    line-height: 30px;
  }
  .md-m-t-30 {
    margin-top: 30px;
  }
  .md-br-4 {
    border-radius: 4px !important;
  }
  .md-br-7 {
    border-radius: 7px !important;
  }
  .md-rotate-180 {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .md-rotate-90 {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .md-br-20 {
    border-radius: 20px !important;
  }
  .md-gap-y-27 {
    grid-row-gap: 27px;
    row-gap: 27px;
  }
  .md-gap-x-24 {
    grid-column-gap: 24px;
    -webkit-column-gap: 24px;
            column-gap: 24px;
  }
  .md-text-ft12-30 {
    font-size: 12px;
    line-height: 30px;
  }
  .md-text-ft16-30 {
    font-size: 16px;
    line-height: 30px;
  }
  .md-fs-21-140per-700 {
    font-weight: 700 !important;
    font-size: 21px !important;
    line-height: 140% !important;
  }
  .md-fs-24-36-700 {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 36px !important;
  }
  .md-fs-28-74-700 {
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 74px !important;
  }
  .md-fs-16-24-500 {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .md-fs-10-11-600 {
    font-weight: 600 !important;
    font-size: 10px !important;
    line-height: 11px !important;
  }
  .md-fs-7-8-400 {
    font-weight: 400 !important;
    font-size: 7px !important;
    line-height: 8px !important;
  }
  .md-fs-7-8-600 {
    font-weight: 600 !important;
    font-size: 7px !important;
    line-height: 8px !important;
  }
  .md-fs-16-24-600 {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .md-fs-10-12-400 {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
  .md-fs-10-12-600 {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }

  .md-fs-24-30-600 {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 30px !important;
  }
  .md-fs-14-150per-400 {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }
  .md-fs-16-19-500 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .md-fs-16-24-400 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .md-fs-16-150per-400 {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  .md-fs-18-150per-700 {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
  }
  .md-fs-18-130per-400 {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
  }
  .md-text-ft12-32 {
    font-size: 12px;
    line-height: 32px;
  }
  .md-text-ft14-17 {
    font-size: 14px !important;
    line-height: 17px !important;
  }
  .md-text-ft11-17 {
    font-size: 11px;
    line-height: 32px;
  }
  .md-fs-12-14-500 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
  .md-fs-12-14-600 {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
  .md-fs-20-24-600 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  .md-fs-24-140per-700 {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 140% !important;
  }
  .md-mt-36 {
    margin-top: 36px !important;
  }
  .md-px-0 {
    padding-inline: 0;
  }
  .md-pt-24 {
    padding-top: 24px;
  }
  .md-pb-0 {
    padding-bottom: 0px;
  }
  .md-pb-36 {
    padding-bottom: 36px;
  }
  .md-bg-lightBlue-10 {
    background-color: #f5f8ff;
  }
  .md-bg-lightBlue-20 {
    background-color: #6696FF!important;
  }
  .md-bg-lightBlue-30 {
    background-color: #CCDCFF !important;
  }
  .md-mt-14 {
    margin-top: 14px;
  }
  .md-w-53 {
    width: 53px;
  }
  .md-w-90 {
    width: 90px !important;
  }
  .md-w-121 {
    width: 121px;
  }
  .md-w-86 {
    width: 86px;
  }
  .md-px-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .md-max-w-130 {
    max-width: 130px;
  }
  .md-h-90 {
    height: 90px;
  }
  .md-w-112 {
    width: 112px;
  }
  .md-h-112 {
    height: 112px !important;
  }
  .md-w-118 {
    width: 118px;
  }
  .md-w-128 {
    width: 128px;
  }
  .md-h-34 {
    height: 34px;
  }
  .md-w-90-per {
    width: 90% !important;
  }
  .md-w-full {
    width: 100% !important;
  }
  .md-h-55 {
    height: 55px !important;
  }
  .md-w-94 {
    width: 94px !important;
  }
  .md-w-101 {
    width: 101px !important;
  }
  .md-h-30 {
    height: 30px !important;
  }
  .md-br-11 {
    border-radius: 11px;
  }
  .md-w-112 {
    width: 112px !important;
  }

  .md-w-144 {
    width: 144px !important;
  }
  .md-h-216 {
    height: 216px;
  }
  .md-h-80 {
    height: 80px;
  }
  .md-h-94 {
    height: 94px;
  }
  .md-h-182 {
    height: 182px !important;
  }
  .md-h-184 {
    height: 184px !important;
  }
  .md-fs-8-10-400 {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
  }
  .md-w-50 {
    width: 50px;
  }
  .md-text-ft18-22 {
    font-size: 18px;
    line-height: 22px;
  }
  .md-text-ft14-22 {
    font-size: 14px;
    line-height: 22px;
  }
  .md-max-w-none {
    max-width: none;
  }
  .md-max-w-305 {
    max-width: 305px;
  }
  .md-pt-32 {
    padding-top: 32px;
  }
  .md-br-18 {
    border-radius: 18px !important;
  }
  .md-w-40 {
    width: 40px;
  }
  .md-h-40 {
    height: 40px;
  }
  .md-h-88 {
    height: 88px;
  }
  .md-w-140 {
    width: 140px;
  }
  .md-w-48 {
    width: 48px;
  }
  .md-h-48 {
    height: 48px;
  }
  .md-flex-col {
    flex-direction: column;
  }
  .md-h-360 {
    height: 360px;
  }
  .md-mr-auto {
    margin-right: auto;
  }
  .md-text-center {
    text-align: center;
  }
  .md-p-t-0 {
    padding-top: 0px;
  }
  .md-ml-0 {
    margin-left: 0px !important;
  }
  .main-div {
    width: 192px;
    height: 253px;
  }
  .md-border-none {
    border: none;
  }
  .md-border-2-blue {
    border: 2px solid #4c84ff !important;
  }
  .md-bg-white {
    background-color: #fff !important;
  }
  .subscribeFooter {
    display: none;
  }
  .md-filter-blue-10 {
    -webkit-filter: brightness(0) saturate(100%) invert(48%) sepia(45%) saturate(3779%)
      hue-rotate(205deg) brightness(100%) contrast(104%);
            filter: brightness(0) saturate(100%) invert(48%) sepia(45%) saturate(3779%)
      hue-rotate(205deg) brightness(100%) contrast(104%);
  }
  .md-text-black {
    color: #000000 !important;
  }
  .md-text-white {
    color: #ffffff !important;
  }
  .featured-apps-container {
    top: 38%;
  }
  .how-its-work-container {
    margin-top: 150px !important;
  }
  .feature-apps-last-child:last-child {
    margin-bottom: 300px !important;
  }
  .testimonial-dots {
    position: absolute;
    top: 300px;
    left: 60px;
    -webkit-transform: translate(100px, 50px) rotate(90deg);
            transform: translate(100px, 50px) rotate(90deg);
  }
  .md-row-gap-35 {
    grid-row-gap: 35px;
    row-gap: 35px;
  }
  .md-p-t-4 {
    padding-top: 4px;
  }
  .suggested-apps-image {
    width: 120.57px;
    height: 98.12px;
  }
  .md-h-15 {
    height: 15px;
  }
  .md-w-86 {
    width: 86px;
  }
  .md-p-b-9 {
    padding-bottom: 9px;
  }
  .md-px-0 {
    padding-inline: 0px !important;
  }
}
@media only screen and (max-width: 880px) and (min-width: 769px) {
  .h-d-600 {
    width: 150%;
    padding-right: 70px !important;
  }
  .p-d-20 {
    margin-right: 20px;
    margin-left: 70px;
    padding-right: 3% !important;
    width: 500px !important;
  }
  .no-scroll-div {
    overflow-x: hidden !important;
  }
}

@media only screen and (max-width: 1121px) {
  .w-100-per-l {
    width: 100%;
  }
  .h-none {
    height: auto;
  }
}
@media only screen and (max-width: 1050px) {
  .flex-wrap-md {
    flex-wrap: wrap;
  }
  .w-100-per-md {
    width: 100% !important;
  }
  .h-1626-md {
    height: 1626px;
  }
  .flex-col-md {
    flex-direction: column;
  }
  .flex-center-md {
    display: flex;
    justify-content: center;
  }
  .w-unset {
    width: unset !important;
  }
  .p-x-md {
    padding-left: 20px;
    padding-right: 20px;
  }
  .text-align-md {
    text-align: center;
  }
}
@media only screen and (max-width: 1200px) {
  .desktop-display-hide{
    display: none;
  }
  .desktop-visibility-hidden{
    visibility: hidden;
  }
}

/* .react-multi-carousel-track{
  gap:10px !important;
} */

/* !important:- media queries put at the bottom and all the css put before the media queries */

